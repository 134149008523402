import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../../components/Common/Sidebar";
import withRouter from "../../components/Common/withRouter";
import { detectDeviceType, toggleContentVisibility } from "../../helpers/ui_helpers";
import axios from "../../util/axiosConfig";

class Tools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: null,
      isMobile: window.innerWidth <= 800,
      showIframe: false,
      iframeSrc: "",
      sidebar: {
        title: "Tools",
        menuItems: [], 
      },
    };
  }

  componentDidMount() {
    const deviceType = detectDeviceType();
    this.setState({ deviceType });
    this.fetchTools();
  }
  componentDidUpdate() {

  }
  fetchTools = async () => {
    try {
      const response = await axios.get("/user/tools");
      const tools = response.data;
      const menuItems = tools.map((tool) => ({
        title: tool.name, 
        image: tool.image || "default-tool-image.svg",
        action: () => this.handleToolClick(tool),
      }));

      this.setState((prevState) => ({
        sidebar: {
          ...prevState.sidebar,
          menuItems,
        },
      }));
    } catch (error) {
      console.error("Error fetching tools:", error);
    }
  };

  handleToolClick = (tool) => {
    if (tool.isOpenLink) {
      window.open(tool.link, "_blank");
    } else {
      const {user} = this.props;
      let token = localStorage.getItem('authToken')
      let link = `${tool.link}?token=${token}`;
      this.setState({ showIframe: true, iframeSrc: link });
    }
  };
  renderComponentBasedOnPath() {
    const { showIframe, iframeSrc } = this.state;
    return showIframe ? (
      <iframe
        src={iframeSrc}
        width="100%"
        height="100%"
        style={{ border: "none" }}
        title="Tool Iframe"
      ></iframe>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
          color: "#666",
        }}
      >
        <div>
          <h3>No Tool Selected</h3>
          <p>Please select a tool from the menu to view it here. Some tools will open in this window, while others may open in a new tab.</p>
          </div>
      </div>
    );
  }

  render() {
    const { sidebar, activeIndex } = this.state;

    return (
      <div className="page-content">
        <div className="right-msg container">
          <div className="d-lg-flex h-tools">
            <div className="chat-leftsidebar" id="leftbar">
              <Sidebar
                sidebar={sidebar}
                onMenuItemClick={(index, item) => {
                  this.setState({ activeIndex: index });
                  item.action(); 
                }}
                activeIndex={activeIndex}
              />
            </div>
            <div className="w-100 user-chat mt-sm-0" id="content">
              {this.renderComponentBasedOnPath()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({User}) => ({
  user: User.user,
});

export default withRouter(connect(mapStateToProps, null)(Tools));
