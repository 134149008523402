/* CHATS */
export const GET_CHATS = "GET_CHATS"
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS"
export const GET_CHATS_FAIL = "GET_CHATS_FAIL"

/* GROUPS */
export const GET_GROUPS = "GET_GROUPS"
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS"
export const GET_GROUPS_FAIL = "GET_GROUPS_FAIL"

/* CONTACTS */
export const GET_CONTACTS = "GET_CONTACTS"
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS"
export const GET_CONTACTS_FAIL = "GET_CONTACTS_FAIL"

/* MESSAGES */
export const GET_MESSAGES = "GET_MESSAGES"
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS"
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL"

export const GET_CHANNEL_MESSAGES = "GET_CHANNEL_MESSAGES"
export const GET_CHANNEL_MESSAGES_SUCCESS = "GET_CHANNEL_MESSAGES_SUCCESS"
export const GET_CHANNEL_MESSAGES_FAIL = "GET_CHANNEL_MESSAGES_FAIL"
export const GET_CHANNEL_BY_SLUG = "GET_CHANNEL_BY_SLUG"
export const GET_FOLLOW_CHANNEL = "GET_FOLLOW_CHANNEL"


export const POST_ADD_MESSAGE = "POST_ADD_MESSAGE"
export const POST_ADD_MESSAGE_SUCCESS = "POST_ADD_MESSAGE_SUCCESS"
export const POST_ADD_MESSAGE_FAIL = "POST_ADD_MESSAGE_FAIL"

export const UPDATE_BLOCKED = 'UPDATE_BLOCKED';
export const UPDATE_BLOCKED_SUCCESS = 'UPDATE_BLOCKED_SUCCESS';
export const UPDATE_BLOCKED_FAIL = 'UPDATE_BLOCKED_FAIL';

export const UPDATE_MUTED = 'UPDATE_MUTED';
export const UPDATE_MUTED_SUCCESS = 'UPDATE_MUTED_SUCCESS';
export const UPDATE_MUTED_FAIL = 'UPDATE_MUTED_FAIL';

export const UPDATE_NOTE = 'UPDATE_NOTE';
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS';
export const UPDATE_NOTE_FAIL = 'UPDATE_NOTE_FAIL';

export const DELETE_CHAT = 'DELETE_CHAT';
export const DELETE_CHAT_SUCCESS = 'DELETE_CHAT_SUCCESS';
export const DELETE_CHAT_FAILURE = 'DELETE_CHAT_FAILURE';


export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAILURE = 'UPDATE_CONTACT_FAILURE';

export const CLEAR_CHAT = 'CLEAR_CHAT';

export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const SELECT_USER = 'SELECT_USER';

export const SOCKET_MESSAGE_RECEIVED = 'SOCKET_MESSAGE_RECEIVED';

export const SOCKET_ERROR_RECEIVED = 'SOCKET_ERROR_RECEIVED';
export const MESSAGE_READ_SUCCESS = 'MESSAGE_READ_SUCCESS';
export const MESSAGE_SEEN_RECEIVED = 'MESSAGE_SEEN_RECEIVED';
export const MESSAGE_READ = 'MESSAGE_READ';
export const MESSAGE_STATUS_UPDATE = 'MESSAGE_STATUS_UPDATE';

export const ADD_MEMBER_REQUEST = 'ADD_MEMBER_REQUEST';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_FAIL = 'ADD_MEMBER_FAIL';
export const REMOVE_CHANNEL_USER = 'REMOVE_CHANNEL_USER';


export const CHANNEL_AVATAR_REQUEST = 'CHANNEL_AVATAR_REQUEST';
export const CHANNEL_AVATAR_SUCCESS = 'CHANGE_CHANNEL_AVATAR_SUCCESS';
export const CHANNEL_AVATAR_FAILURE = 'CHANGE_CHANNEL_AVATAR_FAILURE';

export const UPDATE_CHANNEL_REQUEST = "UPDATE_CHANNEL_REQUEST";
export const UPDATE_CHANNEL_SUCCESS = "UPDATE_CHANNEL_SUCCESS";
export const UPDATE_CHANNEL_FAILURE = "UPDATE_CHANNEL_FAILURE";

export const UPDATE_UNREAD_COUNT = "UPDATE_UNREAD_COUNT";
export const REMOVE_MEMBER = "REMOVE_MEMBER";
export const UPDATE_LAST_MESSAGE = "UPDATE_LAST_MESSAGE";
export const PAY_MESSAGE = "PAY_MESSAGE";
export const PAY_MESSAGE_SUCCESS = "PAY_MESSAGE_SUCCESS";
export const PAY_MESSAGE_FAIL = "PAY_MESSAGE_FAIL";
export const PAYMENT_RECEIVED = "PAYMENT_RECEIVED";
export const UPDATE_PAYMENT_REQUEST = "UPDATE_PAYMENT_REQUEST";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_FAILURE = "UPDATE_PAYMENT_FAILURE";
